<template>
  <div class="about-section">
    <section id="about" class="about">
      <div class="container">
        <div class="section-title">
          <h1>About</h1>
          <p>
            I am Sithira Senanayake, a dedicated Software Engineer with a solid
            foundation in Physical Sciences. My journey in education began at
            Henegama Central College, where I nurtured my academic interests,
            leading me to pursue higher education at the University of Sri
            Jayewardenepura.

            <br /><br />

            At the university, I completed my B.Sc. in Physical Science from
            2017 to 2021, focusing on Computer Science, Statistics, and
            Mathematics. These subjects equipped me with a robust analytical
            framework, enabling me to excel in complex problem-solving
            scenarios.

            <br /><br />

            In my 2.5 years as a Software Engineer, I've gained extensive
            experience with C#, .NET, JavaScript, NodeJS, Angular, and Azure cloud platform,
            working in both local and international settings. This has equipped
            me with a unique blend of technical expertise and customer-facing
            skills. My role often involves direct interactions with foreign
            clients, enhancing my ability to tailor solutions to diverse needs.
            My proficiency in Azure has enabled me to develop scalable, secure
            applications, crucial for modern cloud-based solutions. This
            experience has been instrumental in driving projects to success,
            ensuring they meet client expectations with enhanced efficiency. My
            technical abilities, coupled with international exposure, have been
            pivotal in my professional development, allowing me to deliver
            high-quality, efficient solutions in a dynamic, global environment.

            <br /><br />

            Beyond my technical abilities, I am passionate about Graphics Design
            and Photography. These extra-curricular skills have not only been a
            creative outlet for me but have also helped in honing my attention
            to detail and aesthetic sensibilities, which inadvertently benefit
            my professional role as a Software Engineer.

            <br /><br />

            My journey so far has been both challenging and rewarding. I am
            eager to continue growing, learning, and contributing to the field
            of software engineering, with a keen interest in exploring new
            technologies and methodologies that can drive innovation and
            efficiency in this ever-evolving industry.
          </p>
        </div>
        <div class="sub-section-title">
          <h3>Fun Facts</h3>
          <div class="row no-gutters">
            <div
              class="col-lg-3 col-md-6 align-items-center"
              data-aos="fade-up"
            >
              <div class="count-box">
                <i class="fa-brands fa-fonticons"></i>
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="232"
                  data-purecounter-duration="1"
                  class="purecounter"
                ></span>
                <p>Graphics designer at <strong>Fiverr</strong></p>
              </div>
            </div>

            <div
              class="col-lg-3 col-md-6 align-items-center"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="count-box">
                <i class="fa-brands fa-upwork"></i>
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="521"
                  data-purecounter-duration="1"
                  class="purecounter"
                ></span>
                <p>Web developer at <strong>Upwork</strong></p>
              </div>
            </div>

            <div
              class="col-lg-3 col-md-6 align-items-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div class="count-box">
                <i class="fa-solid fa-camera"></i>
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="1453"
                  data-purecounter-duration="1"
                  class="purecounter"
                ></span>
                <p>Contributor at <strong>Shutterstock</strong></p>
              </div>
            </div>

            <div
              class="col-lg-3 col-md-6 align-items-center"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div class="count-box">
                <i class="fa-solid fa-feather"></i>
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="32"
                  data-purecounter-duration="1"
                  class="purecounter"
                ></span>
                <p>Blooger at <strong>Infital</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Section -->
  </div>
</template>

<script>
export default {
  name: "AboutSection",
};
</script>

<style scoped>
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about-section {
  margin-left: 300px;
  padding: 50px;
  min-height: 100vh;
  padding-left: 150px;
  padding-right: 150px;
  margin-bottom: 50px;
}

.about .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #173b6c;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.about .content ul strong {
  margin-right: 10px;
}

.about .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #149ddd;
  line-height: 0;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.section-title h1::after {
  width: 50px;
}

@media (max-width: 1199px) {
  .about-section {
    margin-left: 0;
    padding: 10px;
  }
}


</style>
