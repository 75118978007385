<template>
  <div class="app-container">
    <SideBar class="sidebar" />
    <HomePage class="homepage" />
  </div>
  <FooterSection class="footer" />
</template>

<script>
import SideBar from "./components/SideBar.vue";
import HomePage from "./views/HomePage.vue";
import FooterSection from "./components/FooterSection.vue";

export default {
  name: "App",
  components: {
    SideBar,
    HomePage,
    FooterSection,
  },

  methods: {
    onscroll(el, listener) {
      el.addEventListener("scroll", listener);
    },
  },
  mounted() {
    // Example usage of onscroll method
    // This is just an example, adjust it according to your needs
    this.onscroll(window, () => {
      console.log("Window is being scrolled");
    });
  },
};
</script>

<style src="./assets/styles/App.css"></style>
