<template>
  <div class="projects-section">
    <section id="projects" class="projects">
      <div class="container">
        <div class="section-title">
          <h1>Projects</h1>
          <p>
            I have been involved in various projects, including several
            enterprise-level applications. Some of them are not mentioned here
            due to agreements with the company/client. Additionally, there are a
            few hobby projects and freelance projects I have undertaken, some of
            which are detailed below.
          </p>
        </div>
        <!--
        <div class="row" data-aos="fade-up">
          <div class="col-lg-12 d-flex">
            <ul id="projects-flters">
              <li data-filter="*" class="filter-active">All</li>
              <li data-filter=".filter-web">Web</li>
              <li data-filter=".filter-desktop">Desktop</li>
              <li data-filter=".filter-mobile">Mobile</li>
            </ul>
          </div>
        </div>
        -->

        <div class="projects-container row justify-content-center">
          <div
            class="col-lg-5 icon-box project-item filter-desktop"
            data-aos="fade-up"
          >
            <div class="icon"><i class="fa-solid fa-ship"></i></div>
            <h4 class="title">ProgmaTM</h4>
            <p class="description">
              ProgmaTM is a thickness-measuring Windows desktop application
              designed for maritime surveyors. It also includes a cloud service,
              which is yet to be implemented.
            </p>
            <p class="technology">.NET | WPF | Azure</p>
          </div>
          <div
            class="col-lg-5 icon-box project-item filter-web"
            data-aos="fade-up"
          >
            <div class="icon"><i class="fa-solid fa-calendar-days"></i></div>
            <h4 class="title">ScheduBox</h4>
            <p class="description">
              ProgmaTM is a thickness-measuring Windows desktop application
              designed for maritime surveyors. It also includes a cloud service,
              which is yet to be implemented.
            </p>
            <p class="technology">.NET | WPF | Azure</p>
          </div>

          <div
            class="col-lg-5 icon-box project-item filter-web"
            data-aos="fade-up"
          >
            <div class="icon"><i class="fa-solid fa-globe"></i></div>
            <h4 class="title">Infital</h4>
            <p class="description">
              ProgmaTM is a thickness-measuring Windows desktop application
              designed for maritime surveyors. It also includes a cloud service,
              which is yet to be implemented.
            </p>
            <p class="technology">.NET | WPF | Azure</p>
          </div>
          <div
            class="col-lg-5 icon-box project-item filter-web"
            data-aos="fade-up"
          >
            <div class="icon"><i class="fa-solid fa-computer"></i></div>
            <h4 class="title">Proganaut</h4>
            <p class="description">
              ProgmaTM is a thickness-measuring Windows desktop application
              designed for maritime surveyors. It also includes a cloud service,
              which is yet to be implemented.
            </p>
            <p class="technology">.NET | WPF | Azure</p>
          </div>

          <div
            class="col-lg-5 icon-box project-item filter-web"
            data-aos="fade-up"
          >
            <div class="icon"><i class="fa-solid fa-computer"></i></div>
            <h4 class="title">Land Price Predictor</h4>
            <p class="description">
              ProgmaTM is a thickness-measuring Windows desktop application
              designed for maritime surveyors. It also includes a cloud service,
              which is yet to be implemented.
            </p>
            <p class="technology">.NET | WPF | Azure</p>
          </div>
          <div
            class="col-lg-5 icon-box project-item filter-web"
            data-aos="fade-up"
          >
            <div class="icon"><i class="fa-solid fa-computer"></i></div>
            <h4 class="title">Portfolio</h4>
            <p class="description">
              ProgmaTM is a thickness-measuring Windows desktop application
              designed for maritime surveyors. It also includes a cloud service,
              which is yet to be implemented.
            </p>
            <p class="technology">.NET | WPF | Azure</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Isotope from "isotope-layout";

export default {
  name: "ProjectsSection",
  data() {
    return {
      isotope: null, // Reference to the Isotope instance
    };
  },
  mounted() {
    this.initIsotope();
  },
  methods: {
    initIsotope() {
      const portfolioContainer = this.$el.querySelector(".projects-container2");
      if (portfolioContainer) {
        // Initialize Isotope
        this.isotope = new Isotope(portfolioContainer, {
          itemSelector: ".project-item",
          layoutMode: "fitRows", // or other layout modes you prefer
        });

        // Event listeners for filter buttons
        const filters = this.$el.querySelectorAll("#projects-flters li");
        filters.forEach((filter) => {
          filter.addEventListener("click", (e) => {
            e.preventDefault();

            // Remove 'filter-active' class from all filters
            filters.forEach((el) => el.classList.remove("filter-active"));

            // Add 'filter-active' class to the clicked filter
            e.target.classList.add("filter-active");

            // Get the filter data attribute and apply it to Isotope
            const filterValue = e.target.getAttribute("data-filter");
            this.isotope.arrange({ filter: filterValue });
          });
        });
      }
    },
  },
};
</script>

<style scoped>
.projects-section {
  margin-left: 300px;
  padding: 50px;
  min-height: 100vh;
  background-color: #f2f2f785;
  margin-bottom: 50px;
}
.projects .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #173b6c;
}
@media (max-width: 1199px) {
  .projects-section {
    margin-left: 0;
    padding: 10px;
  }
}

/*--------------------------------------------------------------
# projects
--------------------------------------------------------------*/

.projects-container {
  margin-top: 40px;
}
.projects .projects-item {
  margin-bottom: 30px;
}

.projects #projects-flters {
  padding: 0;
  margin: 0 auto 35px auto;
  list-style: none;
  text-align: center;
  background: #ffffff;
  border-radius: 50px;
  padding: 2px 15px;
}

.projects #projects-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #272829;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}

.projects #projects-flters li:hover,
.projects #projects-flters li.filter-active {
  color: #149ddd;
}

.projects #projects-flters li:last-child {
  margin-right: 0;
}

.projects .projects-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.projects .projects-wrap::before {
  content: "";
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.projects .projects-wrap .projects-links {
  opacity: 1;
  left: 0;
  right: 0;
  bottom: -60px;
  z-index: 3;
  position: absolute;
  transition: all ease-in-out 0.3s;
  display: flex;
  justify-content: center;
}

.projects .projects-wrap .projects-links a {
  color: #fff;
  font-size: 28px;
  text-align: center;
  background: rgba(20, 157, 221, 0.75);
  transition: 0.3s;
  width: 50%;
}

.projects .projects-wrap .projects-links a:hover {
  background: rgba(20, 157, 221, 0.95);
}

.projects .projects-wrap .projects-links a + a {
  border-left: 1px solid #37b3ed;
}

.projects .projects-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.projects .projects-wrap:hover .projects-links {
  opacity: 1;
  bottom: 0;
}

/*--------------------------------------------------------------
# projects Details
--------------------------------------------------------------*/
.projects-details {
  padding-top: 40px;
}

.projects-details .projects-details-slider img {
  width: 100%;
}

.projects-details .projects-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.projects-details
  .projects-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #149ddd;
}

.projects-details
  .projects-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #149ddd;
}

.projects-details .projects-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(5, 13, 24, 0.08);
}

.projects-details .projects-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.projects-details .projects-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.projects-details .projects-info ul li + li {
  margin-top: 10px;
}

.projects-details .projects-description {
  padding-top: 30px;
}

.projects-details .projects-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.projects-details .projects-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# projects
--------------------------------------------------------------*/

.projects .icon {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  background: #149ddd;
  border-radius: 50%;
  transition: 0.5s;
  border: 1px solid #149ddd;
}

.projects .icon i {
  color: #fff;
  font-size: 24px;
  line-height: 0;
}

.projects .icon-box:hover .icon {
  background: #fff;
}

.projects .icon-box:hover .icon i {
  color: #149ddd;
}

.projects .title {
  margin-left: 80px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.projects .title a {
  color: #343a40;
}

.projects .title a:hover {
  color: #149ddd;
}

.projects .description {
  margin-left: 80px;
  line-height: 24px;
  font-size: 14px;
}

.projects .technology {
  margin-left: 80px;
}
.projects .technology {
  display: inline;
  line-height: 24px;
  font-size: 14px;
  margin-right: 30px;
}

.projects .technology i {
  font-size: 8px;
}

.project-item {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0 30px rgba(5, 13, 24, 0.15);
  margin: 20px;
}
</style>
