<template>
  <div class="education-section">
    <section id="education" class="education">
      <div class="container">
        <div class="section-title">
          <h1>Education</h1>
          <div class="row justify-content-center">
            <div
              class="col-lg-6 align-items-center"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div class="education-item">
                <h4>Bachelor of Science in Physical Science</h4>
                <h5>2017 - 2021</h5>
                <p><em>University of Sri Jayewardenepura, Sri Lanka</em></p>
                <ul>
                  <li>Computer Science</li>
                  <li>Statistics</li>
                  <li>Mathematics</li>
                </ul>
              </div>
              <div class="education-item">
                <h4>Advanced Level Education in Physical Science Stream</h4>
                <h5>2012 - 2016</h5>
                <p><em>Henegama Central College, Gampaha</em></p>
                <ul>
                  <li>Combined Mathematics</li>
                  <li>Physics</li>
                  <li>Checmistry</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-section-title">
          <h3>Certifications</h3>
          <div class="row no-gutters">
            <div
              class="col-lg-4 col-md-6 align-items-center"
              data-aos="fade-up"
            >
              <div class="count-box">
                <img
                  src="../assets/images/1.png"
                  width="30"
                  height="30"
                  alt="Icon Description"
                />
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="232"
                  data-purecounter-duration="1"
                  class="purecounter"
                ></span>
                <p>Microsoft Certified: Azure Fundamentals</p>
              </div>
            </div>
            <div
              class="col-lg-4 col-md-6 align-items-center"
              data-aos="fade-up"
            >
              <div class="count-box">
                <img
                  src="../assets/images/2.png"
                  width="120"
                  height="30"
                  alt="Icon Description"
                />
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="232"
                  data-purecounter-duration="1"
                  class="purecounter"
                ></span>
                <p>Data Science: R Basics</p>
              </div>
            </div>

            <div
              class="col-lg-4 col-md-6 align-items-center"
              data-aos="fade-up"
            >
              <div class="count-box">
                <img
                  src="../assets/images/3.png"
                  width="100"
                  height="30"
                  alt="Icon Description"
                />
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="232"
                  data-purecounter-duration="1"
                  class="purecounter"
                ></span>
                <p>Learning SOLID Programming Principles</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "EducationSection",
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Education
--------------------------------------------------------------*/
.education-section {
  margin-left: 300px;
  padding: 50px;
  min-height: 100vh;
  background-color: #f2f2f785;
  margin-bottom: 50px;
}

.education .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #173b6c;
}
.education-section {
  margin-left: 300px;
  padding: 50px;
}
.section-title h1::after {
  width: 100px;
}
.section-title .row {
  margin-top: 100px;
}
@media (max-width: 1199px) {
  .education-section {
    margin-left: 0;
    padding: 10px;
  }
}

/*--------------------------------------------------------------
# education
--------------------------------------------------------------*/
.education .education-title {
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #050d18;
}

.education .education-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid #1f5297;
  position: relative;
}

.education .education-item h4 {
  line-height: 18px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #050d18;
  margin-bottom: 10px;
}

.education .education-item h5 {
  font-size: 16px;
  background: #e4edf9;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.education .education-item ul {
  padding-left: 20px;
  margin-top: 20px;
}

.education .education-item:last-child {
  padding-bottom: 0;
}

.education .education-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #1f5297;
}

.count-box img {
  margin-bottom: 20px;
}

.sub-section-title {
  margin-top: 50px;
}
</style>
