<template>
  <!-- ======= Hero Section ======= -->

  <sectidivon
    id="homesection"
    class="d-flex flex-column justify-content-center align-items-left homesection"
  >
    <div class="hero-container" data-aos="fade-in">
      <h1>Sithira Senanayake</h1>
      <p>
        I'm a
        <span ref="typed"></span>
      </p>
      <a
        href="#projects"
        class="btn btn-outline-light btn-sm projects-btn"
        role="button"
        aria-disabled="true"
        >Projects</a
      >

      <a
        href="#contact"
        class="btn btn-outline-light btn-sm contact-btn"
        role="button"
        aria-disabled="true"
        >Contact</a
      >
    </div>
  </sectidivon>
  <!-- End Hero -->
</template>

<script>
import Typed from "typed.js";

export default {
  name: "HomeSection",
  mounted() {
    // Initialize Typed.js after the component mounts
    this.initializeTyped();
  },
  methods: {
    initializeTyped() {
      new Typed(this.$refs.typed, {
        strings: [
          "Software Engineer",
          "Tech Enthusiast",
          "Freelance Web Developer",
          " Freelance Graphic Designer",
        ],
        typeSpeed: 50,
        loop: true,
        backSpeed: 25,
        backDelay: 2000,
      });
    },
  },
};
</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.homesection {
  min-height: 100vh;
  background: url("../assets/images/sithira.png") top center;
  background-size: cover;
  margin-left: 300px;
  padding: 80px;
  margin-bottom: 50px;
}

.homesection .hero-container {
  position: relative;
  z-index: 2;
  min-width: 200px;
}

.homesection h1 {
  margin: 0 10px 10px 0;
  font-size: 64px;
  font-weight: 500;
  color: #fff;
}

.homesection p {
  color: #fff;
  margin-top: 10px;
  font-weight: 100;
  margin-bottom: 30px;
  font-size: 26px;
  font-family: "Poppins", sans-serif;
}

.homesection p span {
  color: #fff;
  padding-bottom: 4px;
  letter-spacing: 1px;
  border-bottom: 3px solid #149ddd;
}

.projects-btn {
  margin-right: 20px;
  width: 100px;
}

.contact-btn {
  width: 100px;
}

@media (max-width: 1199px) {
  .homesection {
    margin-left: 0;
    padding: 50px;
  }
}

@media (min-width: 1024px) {
  .homesection {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  .homesection h1 {
    font-size: 40px;
    line-height: 36px;
  }
  .homesection p {
    font-size: 20px;
  }
}
</style>
