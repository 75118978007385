<template>
  <div class="skills-section">
    <section id="skills" class="skills">
      <div class="container">
        <div class="section-title">
          <h1>Skills</h1>
          <div class="card">
            <div class="row">
              <div class="col-lg-3" data-aos="fade-up" data-aos-delay="100">
                <h5>Langulages</h5>
                <ul>
                  <li><i class="fa-solid fa-chevron-right"></i> C#</li>
                  <li>
                    <i class="fa-solid fa-chevron-right"></i> HTML5 / CSS /
                    JavaScript
                  </li>
                  <li><i class="fa-solid fa-chevron-right"></i> TypeScript</li>
                  <li><i class="fa-solid fa-chevron-right"></i> Java</li>
                  <li><i class="fa-solid fa-chevron-right"></i> GoLang</li>
                  <li>
                    <i class="fa-solid fa-chevron-right"></i> SQL (SQL Server &
                    MySQL)
                  </li>
                </ul>
              </div>

              <div class="col-lg-3" data-aos="fade-up" data-aos-delay="100">
                <h5>Frameworks</h5>
                <ul>
                  <li><i class="fa-solid fa-chevron-right"></i> .NET</li>
                  <li>
                    <i class="fa-solid fa-chevron-right"></i> Angualar 13+
                  </li>
                  <li><i class="fa-solid fa-chevron-right"></i> NodeJs</li>
                  <li><i class="fa-solid fa-chevron-right"></i> ExpressJs</li>
                  <li><i class="fa-solid fa-chevron-right"></i> Vue</li>
                </ul>
              </div>

              <div class="col-lg-3" data-aos="fade-up" data-aos-delay="100">
                <h5>Tools and Technologies Skills</h5>
                <ul>
                  <li><i class="fa-solid fa-chevron-right"></i> Azure</li>
                  <li><i class="fa-solid fa-chevron-right"></i> SQL Server</li>
                  <li><i class="fa-solid fa-chevron-right"></i> GitHub</li>
                </ul>
              </div>

              <div class="col-lg-3" data-aos="fade-up" data-aos-delay="100">
                <h5>Other Skills</h5>
                <ul>
                  <li><i class="fa-solid fa-chevron-right"></i> Team work</li>
                  <li>
                    <i class="fa-solid fa-chevron-right"></i> Critical Thinking
                  </li>
                  <li>
                    <i class="fa-solid fa-chevron-right"></i> Time Management
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="sub-section-title testimonials">
          <h3>Testimonials</h3>
          <swiper
            :breakpoints="{
              320: { slidesPerView: 1 },
              740: { slidesPerView: 2 },
              1024: { slidesPerView: 3 },
            }"
            :space-between="50"
            :pagination="{ clickable: true }"
            :navigation="true"
            :loop="true"
            :autoplay="{ delay: 20, disableOnInteraction: false }"
          >
            <swiper-slide>
              <div class="testimonial-item" data-aos="fade-up">
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Having worked with Sithira Senanayake for almost 2 years, I
                  must say he is a highly skilled and dedicated software
                  engineer who consistently developed high-quality... (Read
                  more)
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img
                  src="../assets/images/testimonials/harsha.jpeg"
                  class="testimonial-img"
                  alt=""
                />
                <h4>Harsha Dulshanthi</h4>
                <h5>Technical Lead at Pristine Solutions Pvt Ltd</h5>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="testimonial-item" data-aos="fade-up">
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  I met Sithira during our time in university and he is a
                  capable and reliable person for any challenging role due to
                  his creative problem-solving skills and out of the box
                  thinking.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img
                  src="../assets/images/testimonials/minura.jpeg"
                  class="testimonial-img"
                  alt=""
                />
                <h4>Minura Abeygunawardene</h4>
                <h5>
                  Data Analyst &amp; Master of Data Science (Reading, RMIT)
                </h5>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="testimonial-item" data-aos="fade-up">
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  During his time at Pristine Solution PVT (Ltd), Sithira
                  consistently demonstrated a strong work ethic and a dedication
                  to excellence. Sithira is a proactive learner... (Read more)
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img
                  src="../assets/images/testimonials/dhanushka.jpeg"
                  class="testimonial-img"
                  alt=""
                />
                <h4>Dhanushka De Silva</h4>
                <h5>Senior Software Engineer at Pristine Solutions Pvt Ltd</h5>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="testimonial-item" data-aos="fade-up">
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Sithira offers a great service, creative designs delivered on
                  time and good communication with buyer. I highly recommend him
                  and will use him again in future! Many thanks! :)
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img
                  src="../assets/images/testimonials/unknown.jpeg"
                  class="testimonial-img"
                  alt=""
                />
                <h4>Alasska Luna</h4>
                <h5>Fiverr client from New Zealand</h5>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="testimonial-item" data-aos="fade-up">
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Fantastic Service, and great communication with the seller, I
                  didn't have a great idea of what i wanted but after talking
                  with the seller we came to an agreement. Very fast service and
                  response time.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img
                  src="../assets/images/testimonials/unknown.jpeg"
                  class="testimonial-img"
                  alt=""
                />
                <h4>Bradard</h4>
                <h5>Fiverr client from United Kingdom</h5>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="testimonial-item" data-aos="fade-up">
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Highly recommend! Sithira is fast, courteous, communicates
                  well, and keeps your best interest in mind. I will be working
                  with Sithira in the future.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img
                  src="../assets/images/testimonials/amir.jpeg"
                  class="testimonial-img"
                  alt=""
                />
                <h4>Amir Stephen</h4>
                <h5>Fiverr client from Canada</h5>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="testimonial-item" data-aos="fade-up">
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  Waw, unglaublich gestern bestellt,heute ist schon da,zu
                  schnel?nein Logo,Banner Designs alles Super gemacht!!Ich bin
                  so glücklich. Vielen Dank!!!Immer wieder gerne.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img
                  src="../assets/images/testimonials/unknown.jpeg"
                  class="testimonial-img"
                  alt=""
                />
                <h4>Lina Nadine</h4>
                <h5>Fiverr client from Germany</h5>
              </div>
            </swiper-slide>

            <swiper-slide>
              <div class="testimonial-item" data-aos="fade-up">
                <p>
                  <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                  This seller is excellent. The communication was very good and
                  he is good at taking instructions. Delivery was met before
                  time. I would not hesitate to recommend the seller to others.
                  <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                </p>
                <img
                  src="../assets/images/testimonials/unknown.jpeg"
                  class="testimonial-img"
                  alt=""
                />
                <h4>Anonimus</h4>
                <h5>Fiverr client from United States</h5>
              </div>
            </swiper-slide>

            <div class="swiper-pagination"></div>
          </swiper>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import SwiperCore, { Autoplay } from "swiper";

SwiperCore.use([Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  // other options...
};
</script>

<style scoped>
.skills-section {
  margin-left: 300px;
  padding: 50px;
  min-height: 100vh;
  background-color: #ececec85;
}
.skills .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #173b6c;
}
@media (max-width: 1199px) {
  .skills-section {
    margin-left: 0;
    padding: 10px;
  }
}

.skills-section ul li {
  list-style: none;
}
.skills-section ul i {
  margin-right: 20px;
  color: #149ddd;
}

.skills h5 {
  font-size: 20px;
  color: #173b6c;
  padding: 20px;
}

.card {
  padding: 20px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border: #fff;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials {
  padding: 50px;
  padding-bottom: 0;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  text-align: center;
  min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
  width: 60px;
  border-radius: 50%;
  margin: 0 auto;
}

.testimonials .testimonial-item h4 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  color: #111;
}

.testimonials .testimonial-item h5 {
  font-size: 14px;
  color: #999;
  margin-top: -10px;
  font-weight: 400;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
  color: #c3e8fa;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  color: #c3e8fa;
  font-size: 26px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 15px 15px 15px;
  padding: 20px;
  background: #f9fafa;
  position: relative;
  margin-bottom: 35px;
  border-radius: 6px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.testimonials .testimonial-item p::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 20px solid #f9fafa;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
}

.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #149ddd;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #149ddd;
}

@media (max-width: 1199px) {
  .testimonials {
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>
