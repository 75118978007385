<template>
  <div class="experience-section">
    <section id="experience" class="experience">
      <div class="container">
        <div class="section-title">
          <h1>Experience</h1>
          <div class="row">
            <div class="col-lg-5" data-aos="fade-up" data-aos-delay="100">
              <div class="experience-item">
                <h4>Senior Software Engineer</h4>
                <h5>2024 - Present</h5>
                <p><em>Aventra Group, Singapore (Remote)</em></p>
                <ul>
                  <li>
                    Developing and optimizing cloud solutions on Microsoft
                    Azure.
                  </li>
                </ul>
              </div>
              <div class="experience-item">
                <h4>Software Engineer</h4>
                <h5>2023 - 2024</h5>
                <p><em>Aventra Group, Singapore (Remote)</em></p>
                <ul>
                  <li>
                    Maintaining two Desktop applications created using WPF,
                    which is used in the maritime industry for surveying of
                    ships.
                  </li>
                  <li>
                    Maintaining 2 legacy web applications created using ASP.NET,
                    which are used in the maritime industry for managing
                    cargoes.
                  </li>
                  <li>
                    Developing and optimizing cloud solutions on Microsoft
                    Azure.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-1" data-aos="fade-up" data-aos-delay="100"></div>
            <div class="col-lg-5" data-aos="fade-up" data-aos-delay="100">
              <div class="experience-item">
                <h4>Software Engineer</h4>
                <h5>2023 Jan - 2023 Jul</h5>
                <p><em>Pristine Solutions, Colombo</em></p>
                <ul>
                  <li>
                    Enhanced SAP Business One capabilities by developing and
                    maintaining six based ERP system addons using C#.Net.
                  </li>
                  <li>
                    Successfully implemented a web-based ERP system using
                    Angular and NodeJS.
                  </li>
                  <li>
                    Improved team productivity by mentoring 3 interns on
                    software development best practices, including code reviews
                    and debugging.
                  </li>
                </ul>
              </div>
              <div class="experience-item">
                <h4>Associate Software Engineer</h4>
                <h5>2022 - 2023</h5>
                <p><em>Pristine Solutions, Colombo</em></p>
                <ul>
                  <li>
                    Developed SAP Business One addons and windows services using
                    C#.Net .
                  </li>
                  <li>
                    Successfully implemented a web-based email scheduling system
                    using Angular, .NET, SQLServer.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ExperienceSection",
};
</script>

<style scoped>
.experience-section {
  margin-left: 300px;
  padding: 50px;
  min-height: 100vh;
  margin-bottom: 50px;
}

.experience .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #173b6c;
}
.section-title h1::after {
  width: 100px;
}

.section-title .row {
  margin-top: 100px;
}

@media (max-width: 1199px) {
  .experience-section {
    margin-left: 0;
    padding: 10px;
  }
}

/*--------------------------------------------------------------
# experience
--------------------------------------------------------------*/
.experience .experience-title {
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #050d18;
}

.experience .experience-item {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid #1f5297;
  position: relative;
}

.experience .experience-item h4 {
  line-height: 18px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  color: #050d18;
  margin-bottom: 10px;
}

.experience .experience-item h5 {
  font-size: 16px;
  background: #e4edf9;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.experience .experience-item ul {
  padding-left: 20px;
}

.experience .experience-item ul li {
  padding-bottom: 10px;
}

.experience .experience-item:last-child {
  padding-bottom: 0;
}

.experience .experience-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #1f5297;
}
</style>
