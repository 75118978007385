<template>
  <HomeSection id="home" />
  <AboutSection id="about" />
  <EducationSection id="education" />
  <ExperienceSection id="experience" />
  <SkillsSection id="skills" />
  <ProjectsSection id="projects" />
  <ContactSection id="contact" />
  <a
    href="#"
    class="back-to-top d-flex align-items-center justify-content-center"
    > <i class="fa-solid fa-arrow-up"></i>
  </a>
</template>

<script>
import HomeSection from "../components/HomeSection.vue";
import AboutSection from "../components/AboutSection.vue";
import EducationSection from "../components/EducationSection.vue";
import ExperienceSection from "../components/ExperienceSection.vue";
import SkillsSection from "../components/SkillsSection.vue";
import ProjectsSection from "../components/ProjectsSection.vue";
import ContactSection from "../components/ContactSection.vue";

export default {
  name: "HomePage",
  components: {
    HomeSection,
    AboutSection,
    EducationSection,
    ExperienceSection,
    SkillsSection,
    ProjectsSection,
    ContactSection,
  },
  mounted() {
    window.addEventListener("scroll", this.toggleBackToTop);
    this.toggleBackToTop(); // Initialize once on mount
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.toggleBackToTop);
  },
  methods: {
    toggleBackToTop() {
      const backToTop = document.querySelector(".back-to-top");
      if (window.scrollY > 100) {
        backToTop.classList.add("active");
      } else {
        backToTop.classList.remove("active");
      }
    },
  },
};
</script>

<style>
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #149ddd;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
    text-decoration: none;

}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #2eafec;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}
</style>
