<template>
  <div class="contact-section">
    <section id="contact" class="contact">
      <div class="container">
        <div class="section-title">
          <h1>Contact</h1>

          <div class="row info" data-aos="fade-in">
            <div
              class="col-lg-3 d-flex align-items-center justify-content-center item"
            >
              <i class="fa-solid fa-location-dot"></i>
              <p class="mb-0 ml-2">Gampaha, Sri Lanka</p>
            </div>
            <div
              class="col-lg-3 d-flex align-items-center justify-content-center item"
            >
              <i class="fa-solid fa-phone"></i>
              <p class="mb-0 ml-2">(+94) 718 807 291</p>
            </div>
            <div
              class="col-lg-3 d-flex align-items-center justify-content-center item"
            >
              <i class="fa-solid fa-envelope"></i>
              <p class="mb-0 ml-2">sithirasenanayake@gmail.com</p>
            </div>

            <div
              class="col-lg-3 d-flex align-items-center justify-content-center item"
            >
              <i class="fa-solid fa-check"></i>
              <p class="mb-0 ml-2">Freelance Available</p>
            </div>
          </div>
        </div>

        <div class="sub-section-title">
          <h3>Reach Me</h3>
          <div class="profile">
            <div class="social-links mt-3 text-center">
              <a href="https://twitter.com/_Sithira" class="twitter"
                ><i class="bx bxl-twitter"></i
              ></a>
              <a href="https://www.instagram.com/__sithira/" class="instagram"
                ><i class="bx bxl-instagram"></i
              ></a>
              <a
                href="https://www.linkedin.com/in/sithira-senanayake/"
                class="linkedin"
                ><i class="bx bxl-linkedin"></i
              ></a>
              <a href="https://github.com/SthiraPs" class="github"
                ><i class="bx bxl-github"></i
              ></a>
              <a href="https://www.infital.com/" class="website">
                <i class="fas fa-globe"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ContactSection",
};
</script>

<style scoped>
.contact-section {
  margin-left: 300px;
  padding: 50px;
  min-height: 100vh;
}
.contact .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #173b6c;
}
@media (max-width: 1199px) {
  .contact-section {
    margin-left: 0;
    padding: 10px;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact {
  padding-bottom: 30px;
}
.section-title .row {
  margin-top: 100px;
}
.contact .info {
  padding: 30px;
  padding-left: 0px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .info i {
  font-size: 30px;
}

.contact .info p {
  padding: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  color: #173b6c;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: #149ddd;
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #149ddd;
  color: #fff;
}
 

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sub-section-title {
  margin-top: 120px;
}

.profile h1 a,
.profile h1 a:hover {
  color: #fff;
  text-decoration: none;
}

.profile .social-links a {
  font-size: 25px;
  display: inline-block;
  background: #212431;
  color: #fff;
  line-height: 1;
  padding: 12px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  transition: 0.3s;
  margin: 20px;
}
</style>
