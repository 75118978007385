<template>
  <i
    class="fa-solid fa-bars mobile-nav-toggle d-xl-none"
    @click="toggleMobileNav"
  ></i>
  <header id="header">
    <div class="d-flex flex-column">
      <div class="profile">
        <img
          src="../assets/images/sithira-pro.png"
          alt=""
          class="img-fluid rounded-circle"
        />
        <h1 class="text-light"><a href="index.html">Sithira</a></h1>
        <div class="social-links mt-3 text-center">
          <a href="https://twitter.com/_Sithira" class="twitter"
            ><i class="bx bxl-twitter"></i
          ></a>
          <a href="https://www.instagram.com/__sithira/" class="instagram"
            ><i class="bx bxl-instagram"></i
          ></a>
          <a
            href="https://www.linkedin.com/in/sithira-senanayake/"
            class="linkedin"
            ><i class="bx bxl-linkedin"></i
          ></a>
          <a href="https://github.com/SthiraPs" class="github"
            ><i class="bx bxl-github"></i
          ></a>
          <a href="https://www.infital.com/" class="website">
            <i class="fas fa-globe"></i
          ></a>
        </div>
      </div>

      <nav id="navbar" class="nav-menu navbar">
        <ul>
          <li @click="toggleMobileNav">
            <a href="#home" class="nav-link scrollto active">
              <i class="fa-solid fa-house" style="font-size: 24px"></i>
              <span>Home</span></a
            >
          </li>
          <li @click="toggleMobileNav">
            <a href="#about" class="nav-link scrollto">
              <i class="fa-solid fa-address-card" style="font-size: 24px"></i>
              <span>About</span></a
            >
          </li>
          <li @click="toggleMobileNav">
            <a href="#education" class="nav-link scrollto"
              ><i class="fa fa-school" style="font-size: 22px"></i>
              <span>Education</span></a
            >
          </li>
          <li @click="toggleMobileNav">
            <a href="#experience" class="nav-link scrollto">
              <i class="fa-solid fa-briefcase" style="font-size: 24px"></i>
              <span>Experience</span></a
            >
          </li>
          <li @click="toggleMobileNav">
            <a href="#skills" class="nav-link scrollto">
              <i
                class="fa-solid fa-screwdriver-wrench"
                style="font-size: 24px"
              ></i>
              <span>Skills</span></a
            >
          </li>
          <li @click="toggleMobileNav">
            <a href="#projects" class="nav-link scrollto">
              <i class="fa-solid fa-list-check" style="font-size: 24px"></i>
              <span>Projects</span></a
            >
          </li>
          <li @click="toggleMobileNav">
            <a href="#contact" class="nav-link scrollto">
              <i class="fa-solid fa-envelope" style="font-size: 24px"></i>
              <span>Contact</span></a
            >
          </li>
        </ul>
      </nav>
      <!-- .nav-menu -->
    </div>
  </header>
  <!-- End Header -->
</template>

<script>
export default {
  name: "SideBar",
  mounted() {
    this.addScrollListener();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    addScrollListener() {
      this.onScroll(); // Initialize once on mount
      window.addEventListener("scroll", this.onScroll);
    },
    onScroll() {
      let position = window.scrollY + 200;
      let navbarlinks = document.querySelectorAll("#navbar .scrollto");

      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;
        let section = document.querySelector(navbarlink.hash);
        if (!section) return;

        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    },
    toggleMobileNav() {
      if (window.innerWidth < 1200) {
        const body = document.querySelector("body");
        const mobileNavToggle = document.querySelector(".mobile-nav-toggle");
        body.classList.toggle("mobile-nav-active");
        mobileNavToggle.classList.toggle("fa-bars");
        mobileNavToggle.classList.toggle("fa-x");
      }
    },
  },
};
</script>

<style>
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  transition: all ease-in-out 0.5s;
  z-index: 9997;
  transition: all 0.5s;
  padding: 0 15px;
  background: black;
  overflow-y: auto;
}

#header .profile img {
  margin: 15px auto;
  display: block;
  width: 120px;
  border: 8px solid #2c2f3f;
}

#header .profile h1 {
  font-size: 24px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  -moz-text-align-last: center;
  text-align-last: center;
  font-family: "Poppins", sans-serif;
}

#header .profile h1 a,
#header .profile h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .profile .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #212431;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#header .profile .social-links a:hover {
  background: #149ddd;
  color: #fff;
  text-decoration: none;
}

#main {
  margin-left: 300px;
}

.container,
.container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 1199px) {
  #header {
    left: -300px;
  }

  #main {
    margin-left: 0;
  }

  .container,
  .container-fluid {
    padding-left: 12px;
    padding-right: 12px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu {
  padding: 30px 0 0 0;
  margin: 20px;
}

.navbar a {
  font-size: 2px;
}

.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
}

.nav-menu a,
.nav-menu a:focus {
  display: flex;
  align-items: center;
  color: #a8a9b4;
  padding: 12px 15px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 15px;
}

.nav-menu a i,
.nav-menu a:focus i {
  font-size: 24px;
  padding-right: 8px;
  color: #6f7180;
}

.nav-menu a:hover,
.nav-menu .active,
.nav-menu .active:focus,
.nav-menu li:hover > a {
  text-decoration: none;
  color: #fff;
}

.nav-menu a:hover i,
.nav-menu .active i,
.nav-menu .active:focus i,
.nav-menu li:hover > a i {
  color: #149ddd;
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  color: #149ddd;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border-radius: 50px;
  cursor: pointer;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active #header {
  left: 0;
}
</style>
